<template>
  <v-row class="pl-5">
    <!-- Start: Main area -->
    <v-col cols="8" class="overflow-hidden py-0 d-flex flex-column">
      <!-- Start: Top actions -->
      <top-action
        :search-key="searchKey"
        @updateSearchKey="updateSearchKey($event)"
        :save="save"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5 p-4 h-full bg-white brand-container">
        <brand :search-key="searchKey"></brand>
      </div>
      <!-- End: Data table -->
    </v-col>
    <!-- Start: Filter area -->
    <form-brand class="py-0" type="brand"></form-brand>
    <!-- End: Filter area -->
    <!-- End: Main area -->
    <modal-brand-detail></modal-brand-detail>
  </v-row>
</template>

<script>
import brand from "./components/Brand";
import FormBrand from "./components/FormBrand";
import ModalBrandDetail from "./components/modal/ModalBrandDetail";
import TopAction from "./components/TopAction";

export default {
  components: {
    brand,
    FormBrand,
    TopAction,
    ModalBrandDetail
  },
  data() {
    return {
      selectedBrands: [],
      searchKey: null
    };
  },
  methods: {
    // async pushRouter() {
    //   await this.$router.push({
    //     name: "goods_brands",
    //     query: {
    //       search:
    //         this.searchKey && this.searchKey.length > 0
    //           ? this.searchKey
    //           : undefined,
    //       limit: this.itemPerPage,
    //       page: this.currentPage
    //     }
    //   });
    // },

    async getAllBrands() {
      // Get products
      await this.$store.dispatch("BRAND/getAllBrands");

      await this.$store.dispatch("BRAND/getTreeBrands");

      await this.$store.dispatch("PRODUCT_TYPE/getTreeProductTypes");
    },
    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      // Push router query
    }
  },
  async created() {
    await this.getAllBrands();
  }

  // updateCurrentPage(page) {
  //   if (this.currentPage === page) return;
  //   this.currentPage = page;
  //   this.selectedBrands = [];
  //   // Push router query
  //   this.pushRouter();
  // },

  // async updateItemPerPage(num) {
  //   if (this.itemPerPage === num) return;
  //   this.itemPerPage = num;
  //   this.currentPage = 1;
  //   this.selectedBrands = [];
  //   // Push router query
  //   this.pushRouter();
  // },
};
</script>

<style lang="scss" scoped>
.brand-container {
  overflow-x: scroll;
  height: calc(100vh - 64px - 53px - 40px - 44px);
}
</style>
