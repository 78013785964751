<template>
  <div class="base-tree d-flex h-full">
    <base-tree-item
      v-for="(nodes, level) in childrenNodes"
      :key="level"
      :items="nodes"
      @select="item => selectItem(item, +level)"
      @sort="sortData"
    ></base-tree-item>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import BaseTreeItem from "./BaseTreeItem.vue";

export default {
  components: {
    BaseTreeItem
  },
  props: {
    data: {
      type: Array
    },
    searchKey: {
      type: null
    }
  },
  watch: {
    filteredData() {
      if (this.searchKey === null) {
        this.setInitialChildNodes();
        for (let i = 1; i < this.nodeRoad.length - 1; i++) {
          this.setItemsToChildNodes(
            i,
            this.getChildItemsById(this.nodeRoad[i] || null)
          );
        }
      } else {
        this.setItemsToChildNodes(0, this.filteredData);
      }
    }
  },
  data() {
    return {
      childrenNodes: [[], [], []],
      nodeRoad: [],
      item: {}
    };
  },
  computed: {
    filteredData() {
      if (!this.searchKey) return this.data;

      const fuse = new Fuse(this.data, {
        keys: ["name", "label"],
        threshold: 0.2
      });

      return fuse.search(this.searchKey).map(result => result.item);
    }
  },
  created() {
    console.log("create");
    this.setInitialChildNodes();
  },
  methods: {
    setNodeRoad(level, items) {
      this.nodeRoad[level] = items[0]?.parent_id;
      this.nodeRoad.slice(level + 1, this.nodeRoad.length - level - 1);
    },
    setItemsToChildNodes(level, items) {
      this.childrenNodes = { ...this.childrenNodes };
      this.setNodeRoad(level, items);
      this.childrenNodes[level] = items;
      this.resetNode(level + 1);
    },
    resetNode(level) {
      for (let i = level; i < this.childrenNodes.length; i++) {
        this.childrenNodes[i] = [];
      }
    },
    getChildItemsById(id) {
      return this.data.filter(item => item.parent_id === id);
    },
    selectItem(selectedItem, level) {
      this.item = selectedItem;
      this.level = level;
      // find child items of selected item
      const childItems = this.getChildItemsById(selectedItem.id);

      // select level 2 then render level 3
      this.setItemsToChildNodes(level + 1, childItems);

      //reset node
      this.childrenNodes[level + 2] = [];

      // always emit even click
      this.$emit("click", selectedItem, level);

      // only emit event select when selected item have not child items
      if (!childItems.length) {
        this.$emit("select", selectedItem, level);
      }
    },
    setInitialChildNodes() {
      this.setItemsToChildNodes(0, this.getChildItemsById(null));
    },
    sortData(val) {
      this.$emit("sort", val);
    }
  }
};
</script>

<style>
.base-tree__wrapper {
  display: flex;
  height: 100%;
}
</style>
