<template>
  <div class="brand h-full">
    <base-tree
      v-if="
        brandStatusRequest.value !== 'loading-sortBrands' &&
          brandStatusRequest.value != 'loading-getAllBrands'
      "
      :data="allBrands"
      :search-key="searchKey"
      @click="onSelectItem"
      @sort="sortData"
    ></base-tree>
    <div v-else>
      <v-overlay>
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import BaseTree from "./BaseTree.vue";

export default {
  components: {
    BaseTree
  },
  props: {
    searchKey: {
      type: null
    }
  },
  computed: {
    allBrands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    }
  },
  methods: {
    async onSelectItem(selectItem) {
      await this.$store.dispatch("BRAND/getBrandById", selectItem.id);
      this.$emit("updateSelectedBrand", selectItem);
    },
    async sortData(val) {
      await this.$store.dispatch("BRAND/sortBrands", val);
    }
  }
};
</script>
