<template>
  <tp-modal max-width="960px" name="modal-brand-detail" width="70%">
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          Thêm thương hiệu
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-col>
        <v-card class="tp-filter-scroll pa-5" flat>
          <v-form ref="brandForm" lazy-validation>
            <v-container class="pa-0" fluid>
              <v-row>
                <v-col class="py-0" cols="12">
                  <div class="font-weight-bold mb-2">Tên thương hiệu</div>
                  <tp-text-field
                    v-model="brand.name"
                    placeholder="Nhập tên thương hiệu"
                    validate="required"
                  ></tp-text-field>
                </v-col>

                <v-col class="py-0 mt-1" cols="12">
                  <div class="font-weight-bold mb-2">Mô tả</div>
                  <tp-textarea
                    v-model="brand.description"
                    background-color="white"
                    class="text-body-1"
                    counter
                    dense
                    filled
                    maxlength="320"
                    outlined
                    placeholder="Nhập mô tả cho thương hiệu chính hãng"
                    single-line
                    validate="required"
                  ></tp-textarea>
                </v-col>

                <v-col class="py-0" cols="4">
                  <div class="font-weight-bold mb-2">Ảnh bìa</div>

                  <tp-input-image
                    :acceptedFileTypes="['image/*']"
                    :maxFileSize="500 * 1024"
                    :src.sync="brand.cover"
                    allowCompress
                    allowFileSize
                    allowFileTypeValidation
                    class="flex-grow-1"
                    forceType="jpeg"
                    required
                  ></tp-input-image>
                </v-col>

                <v-col class="py-0" cols="4">
                  <div class="font-weight-bold mb-2">Ảnh thumbnail</div>

                  <tp-input-image
                    :acceptedFileTypes="['image/*']"
                    :maxFileSize="500 * 1024"
                    :src.sync="brand.thumbnail"
                    allowCompress
                    allowFileSize
                    allowFileTypeValidation
                    class="flex-grow-1"
                    forceType="jpeg"
                    required
                  ></tp-input-image>
                </v-col>

                <v-col class="py-0" cols="4">
                  <div class="font-weight-bold mb-2">Logo (512 x 512)</div>

                  <tp-input-image
                    :height="256 * 2"
                    :maxFileSize="300 * 1024"
                    :src.sync="brand.icon"
                    :width="256 * 2"
                    allowCompress
                    allowFileDimensionValidation
                    allowFileSize
                    allowFileTypeValidation
                    class="flex-grow-1"
                    forceType="png"
                    required
                  ></tp-input-image>
                </v-col>

                <v-col class="py-0" cols="12">
                  <div v-if="type && type === 'brand'" class="py-0">
                    <div class="font-weight-bold mb-2">
                      Thương hiệu chính hãng
                    </div>
                    <v-checkbox
                      v-model="brand.isAuthentic"
                      :false-value="0"
                      :true-value="1"
                      class="mt-0"
                      label="Chính hãng"
                    ></v-checkbox>
                    <tp-textarea
                      v-model="brand.noteAuthentic"
                      :disabled="brand.isAuthentic === 0"
                      background-color="white"
                      class="text-body-1"
                      counter
                      dense
                      filled
                      maxlength="320"
                      outlined
                      placeholder="Nhập ghi chú cho thương hiệu chính hãng"
                      required
                      single-line
                    ></tp-textarea>
                  </div>
                </v-col>

                <v-col class="py-0 mt-1 mb-4" cols="12">
                  <div class="font-weight-bold mb-2">
                    Loại thương hiệu cha
                  </div>
                  <treeselect
                    v-model="brand.parent_id"
                    :options="brandTrees"
                    class="custom-treeselect-category"
                    height="100"
                    placeholder="Chọn thương hiệu"
                    search-nested
                  ></treeselect>
                </v-col>

                <v-col class="py-0" cols="12">
                  <div class="py-0">
                    <div class="font-weight-bold mb-2">
                      Thương hiệu chính hãng
                    </div>
                    <v-checkbox
                      v-model="brand.isAuthentic"
                      :false-value="0"
                      :true-value="1"
                      class="mt-0"
                      label="Chính hãng"
                    ></v-checkbox>
                    <tp-textarea
                      v-model="brand.noteAuthentic"
                      :disabled="brand.isAuthentic === 0"
                      background-color="white"
                      class="text-body-1"
                      counter
                      dense
                      filled
                      maxlength="320"
                      outlined
                      placeholder="Nhập ghi chú cho thương hiệu chính hãng"
                      required
                      single-line
                    ></tp-textarea>
                  </div>
                </v-col>

                <v-col class="py-0" cols="12">
                  <form-seo-data
                    :key="brand.id"
                    v-model="brand.seo_data"
                  ></form-seo-data>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-card-actions class="px-0 pb-0 d-flex justify-end">
            <v-btn
              class="grey lighten-5 rounded-lg mr-2"
              color="grey--text text--darken-1"
              text
              @click="closeModal()"
            >
              Hủy
            </v-btn>
            <v-btn
              :loading="brandStatusRequest.value === 'loading-updateBrand'"
              class="cyan lighten-5 rounded-lg mr-2"
              color="primary"
              text
              @click="createBrand()"
            >
              Thêm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-card>
  </tp-modal>
</template>

<script>
import { toFormData } from "@vt7/utils";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import FormSeoData from "@/core/components/form/FormSeoData/index.vue";

export default {
  components: { FormSeoData, Treeselect },
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      formDataIcon: null,
      formDataCover: null,
      modelSiteConfigs: []
    };
  },
  computed: {
    brand() {
      return this.$store.getters["BRAND/brand"];
    },
    brandStatusRequest() {
      return this.$store.getters["BRAND/statusRequest"];
    },
    uploadedCover() {
      return this.$store.getters["BRAND/uploadedCover"];
    },
    uploadedIcon() {
      return this.$store.getters["BRAND/uploadedIcon"];
    },
    brandTrees() {
      return this.$store.getters["BRAND/brandTrees"];
    }
  },
  methods: {
    async onUploadCover() {
      await this.uploadCover(
        toFormData({
          file: this.brand.cover
        })
      );

      // Set uploaded thumbnail
      if (this.brandStatusRequest.value === "success-uploadCover") {
        this.brand.cover = this.uploadedCover;
      } else if (this.brandStatusRequest.value === "error-uploadCover") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa",
          message: this.brandStatusRequest.message
        });

        return;
      }
    },

    async onUploadThumbnail() {
      await this.uploadCover(
        toFormData({
          file: this.brand.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.brandStatusRequest.value === "success-uploadCover") {
        this.brand.thumbnail = this.uploadedCover;
      } else if (this.brandStatusRequest.value === "error-uploadCover") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa",
          message: this.brandStatusRequest.message
        });

        return;
      }
    },

    async onUploadIcon() {
      await this.uploadIcon(
        toFormData({
          file: this.brand.icon
        })
      );

      // Set uploaded thumbnail
      if (this.brandStatusRequest.value === "success-uploadIcon") {
        this.brand.icon = this.uploadedIcon;
      } else if (this.brandStatusRequest.value === "error-uploadIcon") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.brandStatusRequest.message
        });

        return;
      }
    },

    async createBrand() {
      const isValid = this.$refs.brandForm.validate();

      if (!isValid) return;

      // Check thumbnail changes
      if (this.brand.cover instanceof File) {
        await this.onUploadCover();
      }

      // Check thumbnail changes
      if (this.brand.thumbnail instanceof File) {
        await this.onUploadThumbnail();
      }

      // Check mobile thumbnail changes
      if (this.brand.icon instanceof File) {
        await this.onUploadIcon();
      }
      await this.$store.dispatch("BRAND/createBrand", {
        data: this.brand
      });
      // Alert
      if (this.brandStatusRequest.value === "success-createBrand") {
        this.$store.dispatch("BRAND/getAllBrands");

        showToastActionAlert(this, { message: "Đã thêm thành công" });
      } else if (this.brandStatusRequest.value === "error-createProductType") {
        showModalAlertError(this, {
          title: "Không thể thêm thương hiệu",
          message: this.brandStatusRequest.message
        });
      }
      this.closeModal();
    },

    resetBrand() {
      this.$store.dispatch("BRAND/resetBrand");
      this.$refs.brandForm.resetValidation();
    },

    async uploadCover(formData) {
      if (!formData) return;

      await this.$store.dispatch("BRAND/uploadCover", formData);
    },
    async uploadIcon(formData) {
      if (!formData) return;

      await this.$store.dispatch("BRAND/uploadIcon", formData);
    },

    closeModal() {
      this.resetBrand();
      this.$modal.hide({
        name: "modal-brand-detail"
      });
      this.activeTab = null;
      this.activeSubBrand = 0;
    }
  }
};
</script>

<style lang="scss">
.custom-treeselect-category .vue-treeselect__value-container {
  font-size: 14px;
}
</style>
